import React from 'react';

import Categories from '../components/content/our-products/categories';

const CategoriesTemplate = ({ pageContext, location }) => {
  return (
    <Categories categories={pageContext.categories || []} location={location} />
  );
};

export default CategoriesTemplate;
