import React from 'react';

import Img from 'gatsby-image';
import { Link } from 'gatsby';

import Layout from '../../layout/main';
import Head from '../../layout/head';

import NoImageAvailable from '../../widgets/no-image-available';

const Items = ({ image, description, link }) => {
  let fluid;
  if (image && image.childImageSharp && image.childImageSharp.fluid) {
    fluid = image.childImageSharp.fluid;
  }

  return (
    <div className="mb-2 border-gray-1 d-flex">
      <div className="mr-3">
        <Link
          to={`${link}`}
          className="text-deco
          on-none"
        >
          {fluid ? (
            <Img fluid={fluid} className="minw-130 minh-130 mw-130 mh-130" />
          ) : (
            <NoImageAvailable height={130} width={130} />
          )}
        </Link>
      </div>
      <div className=" ganen-green-bold align-self-center text-center w-100">
        <Link
          to={`${link}`}
          className="text-decoration-none text-green-brown ganen-bold"
        >
          {description}
        </Link>
      </div>
    </div>
  );
};

const Categories = ({ categories }) => {
  return (
    <Layout>
      <Head title={'Products'} />

      <div className="my-5 text-center">
        <div className=" my-5">
          <h3 className="fs-35 ganen-green-bold">Browse by Category</h3>
          <div className="mb-2">or</div>
          <a
            href="/downloads/ganen-catalogue.pdf"
            className="btn bg-ganen-brown rounded-10 text-white px-4"
            download
          >
            Download our catalogue
          </a>
        </div>

        <div className="mb-5 categories-grid mw-1500 mx-auto">
          {categories.map(({ node }) => {
            return (
              <Items
                key={node.name}
                image={node.image}
                description={node.label}
                link={`/our-products/${node.name}`}
              />
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Categories;
